const AuditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path d="M179.2,51.2H128c-42.4,0-76.8,34.4-76.8,76.8v307.2c0,42.4,34.4,76.8,76.8,76.8h256c42.4,0,76.8-34.4,76.8-76.8V128   c0-42.4-34.4-76.8-76.8-76.8h-51.2c-14.1,0-25.6,11.5-25.6,25.6c0,14.1,11.5,25.6,25.6,25.6H384c14.1,0,25.6,11.5,25.6,25.6v307.2   c0,14.1-11.5,25.6-25.6,25.6H128c-14.1,0-25.6-11.5-25.6-25.6V128c0-14.1,11.5-25.6,25.6-25.6l51.2,0c14.1,0,25.6-11.5,25.6-25.6   C204.8,62.7,193.3,51.2,179.2,51.2z" />
      <path d="M230.4,25.6v25.6h51.2c14.1,0,25.6,11.5,25.6,25.6c0,14.1-11.5,25.6-25.6,25.6h-51.2c-14.1,0-25.6-11.5-25.6-25.6   c0-14.1,11.5-25.6,25.6-25.6V25.6V0c-42.4,0-76.8,34.4-76.8,76.8c0,42.4,34.4,76.8,76.8,76.8l51.2,0c42.4,0,76.8-34.4,76.8-76.8   C358.4,34.4,324,0,281.6,0h-51.2V25.6z" />
      <path d="M161.1,325.3l51.2,51.2c4.8,4.8,11.4,7.5,18.1,7.5c6.7,0,13.3-2.7,18.1-7.5l102.4-102.4c10-10,10-26.2,0-36.2   c-10-10-26.2-10-36.2,0l-84.3,84.3l-33.1-33.1c-10-10-26.2-10-36.2,0C151.1,299.1,151.1,315.3,161.1,325.3L161.1,325.3z" />
    </g>
  </svg>
)

export default AuditIcon
