"use client"
import { type Session } from "next-auth"

import Header from "@/components/header"
import HeaderNavbar from "@/components/header-navbar/HeaderNavbar"
import Sidebar from "@/components/sidebar/Sidebar"

type TClientLayoutProps = {
  children: React.ReactNode
  session: Session | null
}

export default function ClientLayout(props: TClientLayoutProps) {
  return (
    <div className="h-full flex-col">
      <HeaderNavbar session={props.session} />
      <Header session={props.session} />
      <div className="flex h-full w-full gap-6 p-6 pt-[110px]">
        <Sidebar session={props.session} />
        {/* Main Content */}
        <div className="h-max grow overflow-x-hidden pb-6">
          {props.children}
        </div>
        {/* Main Content */}
      </div>
    </div>
  )
}
