import { IS_PRODUCTION, IS_CANARY_PREPROD } from "shared-utils"

export const ENTERPRISE_VALIDATION = "MARKETPLACE-ENTERPRISE_VALIDATION"

export const DISPLAY_CURATION_INFO_PROCESS =
  "PRODUCT-DISPLAY_CURATION_INFO_PROCESS"

export const SECTORAL_CURATION = "MARKETPLACE-SECTORAL_CURATION"
export const OPTIONAL_CURATION_IMPROVEMENT =
  "MARKETPLACE-OPTIONAL_CURATION_IMPROVEMENT"

export const NEW_CURATION_FLOW_RELEASE = "PRODUCT-NEW_CURATION_FLOW_RELEASE"

export const PRODUCT_PRINCIPAL = "MARKETPLACE-PRODUCT_PRINCIPAL"
export const PRODUCT_PRINCIPAL_SECTORAL =
  "MARKETPLACE-PRODUCT_PRINCIPAL_SECTORAL"

export const METABASE_AKUN_INAPROC = "ACCOUNT-METABASE-AKUN-INAPROC"

export const SHOW_SOCKET_GET_UPLOAD_STATUS_V1 =
  "ACCOUNT-KILL_SWICTH_AUTH_UPLOAD_STATUS_SOCKET"
export const FLAG_NEW_COMPONENT_AVATAR = !IS_PRODUCTION || IS_CANARY_PREPROD
