"use client"

import { type PropsWithChildren, createContext } from "react"

import { type FeatureFlag } from "./getFlag"

export const FeatureFlagsContext = createContext<FeatureFlag | null>(null)

export default function FeatureFlagsProvider({
  value,
  children,
}: PropsWithChildren & { value: FeatureFlag | null }) {
  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
