"use client"

import Link from "next/link"
import { type Session } from "next-auth"

import { ZENDESK_URL } from "@/config/client"

type THeaderNavbarProps = {
  session: Session | null
}

const HeaderNavbar = ({ session }: THeaderNavbarProps) => {
  return (
    <div className="fixed top-0 z-[9999] mx-auto h-[34px] w-full bg-tertiary25">
      <div className="mx-auto flex w-full items-center px-10">
        <div className="flex h-[34px] w-full justify-end gap-6">
          <div className="flex items-center gap-6">
            {session?.user?.id && (
              <Link
                key="header-navbar"
                href={ZENDESK_URL}
                target="_blank"
                className="text-sm text-tertiary300"
              >
                Pusat Bantuan
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderNavbar
